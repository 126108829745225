<template>
	<Breadcrumbs></Breadcrumbs>
  <MDBCard class="m-3" :class="{ 'd-none':(add_dialog || edit_dialog)}">
    <MDBCardBody>
      <MDBContainer fluid class="mt-3">
        <MDBRow>
          <MDBCol md="2">
            <MDBInput :label="$t('COMMON.EMAIL')" :placeholder="$t('COMMON.EMAIL')" v-model="where.email" />
          </MDBCol>
          <!-- <MDBCol md="2">
            <MDBInput :label="$t('COMMON.REFER')" :placeholder="$t('COMMON.REFER')" v-model="where.upper" />
          </MDBCol> -->
          <MDBCol md="2">
            <MDBInput :label="$t('COMMON.NAME')" :placeholder="$t('COMMON.NAME')" v-model="where.name" />
          </MDBCol>
          <MDBCol class="mb-2 text-end">
            <MDBBtn color="primary" @click="GetTable">{{ $t("COMMON.SEARCH") }}</MDBBtn>
          </MDBCol>
        </MDBRow>
        <DataTable
          :header="headers"
          :tabledata="desserts"
          v-model:total="total"
          v-model:options="options"
          v-model:loading="loading"
        >
          <template v-slot:name="{ item }">
            {{ item.name }}/{{ item.serial }}
          </template>
          <template v-slot:refer="{ item }">
            {{ (item.refer)?`${item.refer.name}/${item.refer.serial}`:'' }}
          </template>
          <!-- 時間轉換 -->
          <template v-slot:created_at="{ item }">
            {{ Common.TimeFormat(item.created_at) }}
          </template>
          <!-- 操作template -->
          <template v-slot:uuid="{ item }">
            <a @click="GetDetail(item.uuid)" data-bs-toggle="tooltip" :title="$t('MEMBER-LIST.EDIT-ITEM')" href="javascript:void(0)" class="me-2">
              <MDBIcon icon="edit" iconStyle="fas" class="text-primary"/>
            </a>
            <a @click="DeleteAction(item.uuid)" data-bs-toggle="tooltip" :title="$t('MEMBER-LIST.DELETE-ITEM')" href="javascript:void(0)" class="me-2">
              <MDBIcon icon="trash-alt" iconStyle="fas" class="text-danger"/>
            </a>
          </template>
        </DataTable>
      </MDBContainer>
    </MDBCardBody>
  </MDBCard>
	<MemberForm action="insert" v-model:model="add_dialog"></MemberForm>
	<MemberForm action="update" v-model:model="edit_dialog" v-model:uuid="EditUUid"></MemberForm>
</template>

<script>
import { MDBContainer, MDBIcon, MDBCard, MDBCardBody, MDBRow, MDBCol, MDBInput, MDBBtn } from 'mdb-vue-ui-kit';
import DataTable from '@/components/Plugin/DataTable';
import Breadcrumbs from '@/components/Breadcrumbs';
import MemberForm from '@/components/Form/Member/MemberForm';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import { ref, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
export default {
  name: "ReferMemberRecord",
  components: {
		DataTable,
		MDBContainer,
		MDBIcon,
		Breadcrumbs,
		MemberForm,
    MDBCard,
    MDBCardBody,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBBtn
  },
  setup() {
    const i18n = useI18n();
    const add_dialog = ref(false);
    const edit_dialog = ref(false);
    const loading = ref(true);
    const EditUUid = ref("");
    const DeleteUUid = ref("");
    const desserts = ref([]);
    const total = ref(0);
    const Common = CommonService;
    const tags = reactive([]);
    const options = reactive({
      page: 1,
      itemsPerPage: 10,
      sortBy: ["created_at"],
      sortDesc: ["desc"],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false
    });
    const where = reactive({
      upper: "",
      name: "",
      email:"",
    });
    const headers = reactive([
      {
        text: i18n.t("COMMON.SEQUENCE"),
        align: "start",
        sortable: false,
        value: "no",
        width: 50
      },
      { text: i18n.t("COMMON.REFER"), value: "refer" },
      { text: i18n.t("COMMON.NAME"), value: "name" },
      { text: i18n.t("COMMON.EMAIL"), value: "email" },
      { text: i18n.t("COMMON.CREATE_AT"), value: "created_at" },
    ]);
		return {
      add_dialog,
      edit_dialog,
      loading,
      EditUUid,
      DeleteUUid,
      options,
      headers,
      desserts,
      total,
      Common,
      tags,
      where
    };
  },
  mounted() {
    this.GetTable();
  },
  watch: {
		options: {
      handler() {
        this.GetTable();
      },
      deep: true
    }
  },
  provide () {
    return {
      GetTable: this.GetTable,
      tags: this.tags
    }
  },
  methods: {
		GetTable() {
      return new Promise(resolve => {
        this.loading = true;
        ApiService.query(
          "/Admin/v1/ReferMember",
          {
            params: Object.assign(CommonService.SortHandleObject(this.options),this.where)
          }
        ).then(response => {
          if (response.status == 200) {
            this.desserts = CommonService.DataNoHandle(
              response.data.data.data,
              this.options.page,
              this.options.itemsPerPage
            );
            this.total = response.data.data.total;
            resolve(response);
          }
          this.loading = false;
        });
      });
    },
    GetDetail(uuid){
			this.EditUUid = uuid;
    }
  }
};
</script>